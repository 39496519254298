@import 'ikp/src/_variables.scss';

/* Styles are treated global, so we wrap it in the container surrounding it */
.ikp-basic-card {
  .card-basic.has-background {
    @include media-breakpoint-up(lg) {
      padding-right: 65px;
      & > .card-background:after {
        width: 103px;
        min-height: 364px; // looks hacky but the original image is positioned similarly
        margin-right: -28px;
        margin-top: -10px;
      }
    }
    .card-basic-body {
      position: relative;
    }
  }
}
